import * as grid from "../styles/grid.module.scss";
import * as styles from "../styles/vippeloft-vs-vippeextensions.module.scss";
import ArticleLayout from "../components/articleLayout/ArticleLayout";
import Divider from "../components/divider/Divider";
import PageTitle from "../components/contentHeadings/PageTitle";
import React from 'react';
import Seo from "../components/Seo";

const Sml = () => {

    const data = [
        {
            title: 'Utgangspunkt',
            vl: 'Vippeløft bøyer dine naturlige vipper. Med lite naturlige vipper blir det en liten effekt, med mye naturlige vipper blir det mye effekt. Vi legger ikke til noe på hårene, bare bøyer de du allerede har.',
            vx: 'Vippeextensions legger til lengde og fylde, som kan gjøres på alle hår. Med lite naturlige vipper kan det bli mye effekt, og med mye naturlige vipper kan det bli en kjempestor effekt. Vi tenker på vekten av det kunstige håret når vi legger det på, og vurderer hva som vil bli best. Kortere vipper kan være veldig tette og veie like mye som lange, men luftige vipper for eksempel.'
        },
        {
            title: 'Varighet',
            vl: 'Vippeløft 4-12 uker før effekten er helt borte. Bøyen holder seg uansett, og vil ikke forsvinne så lenge dine naturlige vipper er der. Man får nye vipper hver dag, og de nye vippene vil være rette. Derfor forsvinner løftet gradvis. ',
            vx: 'Vippeextensions holder i 4-8 uker, men avhenger av hvordan vippene bytter seg ut og hvordan man behandler dem. Kan falle av med feil behandling (oljebaserte produkter for eksempel). Vi anbefaler å fylle på ca hver 3 uke for at de skal se fyldige og fine ut. Som vippeløft vil vippene bytte seg ut, så vippeextensions vil sakte men sikkert falle av med de naturlige vippene.'
        },
        {
            title: 'Påfyll',
            vl: 'Vippeløft kan ikke ta påfyll, men må vente minst 4 uker før neste vippeløft. Da gjør vi samme behandling om igjen.',
            vx: 'Vippeextensions kan fylle på så ofte man vil, men vi anbefaler hver 3 uke. Hvis du ikke fyller på vil alle falle av etter en stund, og du må i så fall ta nytt sett.'
        },
        {
            title: 'Effekt',
            vl: 'Vippeløft gir bare bøy, ikke lengde. Men vippene vil se lengre ut fordi man ser hele håret. Gir en lett sminket look.',
            vx: 'Vippeextensions kan gi både lengde, volum og bøy, og man kan ofte velge helt selv hvordan de skal bli. Dette gir flere designmuligheter og effekter - alt fra en lett sminket look til kraftig dramatisk look.'
        },
        {
            title: 'Behandlingstid',
            vl: 'Vippeløft tar 30-60 min.',
            vx: 'Vippeextensions tar 1,5-3 timer, avhengig av om du vi ønsker klassisk, volum eller mega-volum.'
        },
        {
            title: 'Slitasje',
            vl: 'Vippeløft skader ikke de naturlige vippene så lenge det ikke blir gjort fast oftere enn hver 4 uke. Mange vippeløftbehandlinger nært i tid kan slite på vippene.',
            vx: 'Vippeextensions sliter ikke på vippene så lenge det ikke blir lagt for lange, for tunge eller sammenlimte extensions. Blir dine naturlige vipper slitt kan man måtte ta en pause så nye sterke vipper kan vokse ut. Vi gir deg råd i salongen slik at du kan kan bruke extensions så lenge du ønsker uten at vippene dine blir slitt.'
        },
        {
            title: 'Etterbehandling',
            vl: 'Vippeløft krever ingen spesiell etterbehandling utenom de første 24 timene der man burde unngå å ta noe på vippene. Man kan bruke sminke på vippene til daglig.',
            vx: 'Vippeextensions burde børstes og vaskes, og må holdes unna oljebaserte produkter. De første 24 timene bør man unngå vann og høy luftfuktighet. Det anbefales å ikke bruke sminke på vippene til daglig. '
        },
        {
            title: 'Pris',
            vl: 'Vippeløft koster 650,- uten farge, og 890,- med farge. Vippeløft for to med farge koster 1390,- til sammen, som tilsvarer 695,- på hver person.',
            vx: 'Vippeextensions koster 1590,- for naturlig volum eller klassisk, og 1790,- for dramatisk volum eller brune. Påfyll varierer etter hvor lang tid du trenger, men en time er 790,-.'
        }
    ];

    return(
        <ArticleLayout>
            <Seo title={'Vippeløft vs Vippeextensions'} />
            <PageTitle>Vippeløft vs Vippeextensions</PageTitle>
            <p>Hva skal man velge?</p>
            <p>Vippeløft er en utrolig populær behandling hos oss! Vi har ca. like mange kunder som tar vippeløft og vippeextensions. Hvis du ikke har prøvd noen av dem så kan det være vanskelig å vite hva som passer for deg. Nedenfor har du en oversiktlig sammenlikning. </p>
            <Divider color={'blue'}/>
            {
                data.map((txt, index) => (
                    <div className={styles.vsBlock} key={`vs_${index}`}>
                        <h6 className={styles.vsHeading}>{ txt.title }</h6>
                        <div className={grid.container}>
                            <div className={grid.xs12Sm6P12 + " " + styles.vsText}>{ txt.vl }</div>
                            <div className={grid.xs12Sm6P12 + " " + styles.vsText}>{ txt.vx }</div>
                        </div>
                    </div>
                ))
            }
        </ArticleLayout>
    )
};

export default Sml;